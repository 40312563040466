import { List, Datagrid, TextField, EditButton } from 'react-admin';

const ListSystemParameters = props => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="battle_duration_minutes" label="Battle Duration Minutes"/>
        <TextField source="currency" label="System currency"/>
        <EditButton />
      </Datagrid>
    </List>
  );
}
export default ListSystemParameters;