import { useState } from "react";
import axios from "axios";

const useUploadToS3 = () => {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("");

  function makeProgress({ loaded, total }) {
    return Math.round((100 * loaded) / total);
  }

  function doUpload(form, file) {
    let formData = new FormData();
    Object.keys(form.fields).forEach((key) =>
      formData.append(key, form.fields[key])
    );
    formData.append("file", file);
    startUpload(form.url, formData);
  }

  function startUpload(url, formData) {
    axios
      .post(url, formData, {
        onUploadProgress: (progressEvent) => {
          setProgress(makeProgress(progressEvent));
        },
      })
      .then((response) => {
        setStatus("done");
      })
      .catch((error) => {
        setStatus(`error: ${error}`);
      });
  }

  return {
    progress,
    status,
    doUpload,
    startUpload,
  };
};
export default useUploadToS3;