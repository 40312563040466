import { SimpleForm, TextInput, DateInput } from "react-admin";
import React from "react";

const UsuarioForm = () => {
  return (
    <SimpleForm>
      <TextInput source="id" label={"Id"} fullWidth disabled/>
      <TextInput source="apelido" label={'Apelido'}/>
      <TextInput source="email" label={'Email'}/>
      <DateInput source="nascimento" label={'Data de nascimento'}/>
      <TextInput source="telefone" label={'Telefone'}/>
      <TextInput source="invitation_code" label={'Código de convite'} fullWidth/>
      <TextInput source="image_url" label={"Image"} fullWidth/>
      <TextInput source="invited_by" label={"Quem convidou"} fullWidth/>
    </SimpleForm>
  );
};
export default UsuarioForm;
