import { Edit } from 'react-admin';
import SystemParametersForm from "./system_parameters_form";

const EditSystemParameters = (props) => {
  return (
    <Edit {...props}>
      <SystemParametersForm/>
    </Edit>
  );
};

export default EditSystemParameters;