import React from "react";
import {
  TextField,
  SimpleShowLayout,
  DateField,
  FunctionField
} from 'react-admin';

const AdminLogShowForm = () => {
  return (
    <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="agent" label="Agent"/>
        <DateField showTime source="action_date_time" label="Date Time"/>
        <FunctionField render={record => {
            const mappedTypes = {
              "ActionType.TRANSFER": "TRANSFER",
              "ActionType.CREATE": "CREATE",
              "ActionType.UPDATE": "UPDATE",
              "ActionType.DELETE": "DELETE",
            }
            return mappedTypes[record.action_type]
        }} label="Type"/>
        <FunctionField render={record => {
            const mappedTypes = {
              "ActionStatus.SUCCESS": "SUCCESS",
              "ActionStatus.FAILED": "FAILED",
            }
            return mappedTypes[record.status]
        }} label="Status"/>
        <FunctionField sx={{maxWidth: 200}} render={record => record.action_data.replaceAll("\\", "")} label="Data"/>
    </SimpleShowLayout>
  );
};
export default AdminLogShowForm;
