import {NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput} from "react-admin";
import React from "react";

const NftStoreForm = () => {
  return (
    <SimpleForm>
      <TextInput source="id" label={"Id"} fullWidth disabled/>
      <TextInput source="title" label={'Title'}/>
      <TextInput source="subtitle" label={'Subtitle'}/>
      <ReferenceInput name="nft_id"
                      source="nft_id"
                      label="Nft"
                      reference="nfts"
                      fullWidth>
        <SelectInput />
      </ReferenceInput>
      <NumberInput source="price" />
      <NumberInput source="validity" label="Validity (days)"/>
    </SimpleForm>
  );
};
export default NftStoreForm;
