import { Edit } from 'react-admin';
import TorneioForm from "./torneio_form";

const EditTorneio = (props) => {
  return (
    <Edit {...props}>
      <TorneioForm/>
    </Edit>
  );
};

export default EditTorneio;