import {Create} from 'react-admin';
import MatchbetStoreForm from "./matchbet_store_form";

const CreateMatchbetStore = (props) => {
  return (
    <Create {...props} redirect="list">
      <MatchbetStoreForm/>
    </Create>
  );
};

export default CreateMatchbetStore;