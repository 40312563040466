import { Create } from 'react-admin';
import TorneioForm from "./platform_form";

const CreatePlatform = (props) => {
  return (
    <Create {...props} redirect="list">
      <TorneioForm/>
    </Create>
  );
};

export default CreatePlatform;