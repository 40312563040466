import { Create } from 'react-admin';
import GameForm from "./game_form";

const CreateGame = (props) => {
  return (
    <Create {...props} redirect="list">
      <GameForm/>
    </Create>
  );
};

export default CreateGame;