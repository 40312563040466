/* eslint-disable no-console */
/* eslint-disable consistent-return */
import { API } from "aws-amplify";
import config from "../config/constants";
import { ENDPOINTS } from "../config/endpoints";

const getUploadURL = async (router) => {
  try {
    const response = await API.get(config.APIS.MATCHBET_ADMIN, router, {});
    return response.data;
  } catch (error) {
    throw new Error("Can't get the upload URL.");
  }
};

export async function getSignedURL(isMatchBetStore = false) {
  const endpoint = isMatchBetStore ? `matchbet-store/${ENDPOINTS.getSignedURL}` : ENDPOINTS.getSignedURL;
  return await API.get(
    config.APIS.MATCHBET_ADMIN, endpoint
  );
}

export const servicesApis = {
  getUploadURL,
};
