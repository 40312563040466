import {List, Datagrid, ShowButton, ChipField, FunctionField, DateField, TextField} from 'react-admin';

const ListAdminLogs = props => {
  return (
    <List {...props} sort={{ field: 'action_date_time', order: 'DESC' }}>
      <Datagrid>
        <ChipField source="id" label="Log ID"/>
        <TextField source="agent" label="Agent"/>
        <DateField showTime source="action_date_time" label="Date Time"/>
        <FunctionField render={record => {
            const mappedTypes = {
              "ActionType.TRANSFER": "TRANSFER",
              "ActionType.CREATE": "CREATE",
              "ActionType.UPDATE": "UPDATE",
              "ActionType.DELETE": "DELETE",
            }
            return mappedTypes[record.action_type]
        }} label="Type"/>
        <FunctionField render={record => {
            const mappedTypes = {
              "ActionStatus.SUCCESS": "SUCCESS",
              "ActionStatus.FAILED": "FAILED",
            }
            return mappedTypes[record.status]
        }} label="Status"/>
        <ShowButton />
      </Datagrid>
    </List>
  );
}
export default ListAdminLogs;