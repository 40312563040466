import {NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput, BooleanInput} from "react-admin";
import React from "react";

const MatchBetStoreForm = () => {
  return (
    <SimpleForm>
      <TextInput source="id" label={"Id"} fullWidth disabled/>
      <ReferenceInput name={"Product"} source={"product_id"} reference={"products"}>
        <SelectInput optionText={"name"} optionValue={"id"}/>
      </ReferenceInput>
      <NumberInput source="price" label={"Price (In cents)"} fullWidth/>
      <NumberInput source="units_pack" label={"Units Pack"} fullWidth/>
      <BooleanInput source="active" label={"Is Active"} fullWidth/>
    </SimpleForm>
  );
};
export default MatchBetStoreForm;
