import { Show } from 'react-admin';
import AdminLogShowForm from './adminLog-show-form';

const ShowAdminLog = (props) => {
  return (
    <Show {...props}>
      <AdminLogShowForm/>
    </Show>
  );
};

export default ShowAdminLog;