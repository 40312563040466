import { Show } from 'react-admin';
import TorneioShowForm from './tournament-show-form';

const ShowTorneio = (props) => {
  return (
    <Show {...props}>
      <TorneioShowForm/>
    </Show>
  );
};

export default ShowTorneio;