import { SimpleForm, TextInput } from "react-admin";
import * as PropTypes from "prop-types";

function InputField(props) {
  return null;
}

InputField.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string
};
const PlatformForm = () => {
  return (
    <SimpleForm>
      <TextInput source="name" label={'Nome'}/>
      <TextInput source="image_url" label={"Image"} fullWidth/>
      <TextInput source="id" label={"Id"} fullWidth/>
    </SimpleForm>
  );
};
export default PlatformForm;
