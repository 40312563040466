/* eslint-disable react-hooks/exhaustive-deps */
import { Button, useLoading, useRecordContext } from "react-admin";
import { useController, useFormContext } from "react-hook-form";
import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { UploadState } from "./UploadState";
import { UploadButton } from "./UploadButton";
import { ResetButton } from "./ResetButton";
import useUploadToS3 from "../../hooks/UploadToS3";
import LinearProgress from "@mui/material/LinearProgress";
import { getSignedURL } from "../../services/service.api";

const ImageUploader = (props) => {
  const { source, label = "Image Uploader", isMatchBetStore = false } = props;
  const record = useRecordContext(props);
  const form = useFormContext();
  const isLoading = useLoading();
  const fileInputRef = useRef();

  const initialValue = record ? record[source] : "";

  const input_file = useController({
    name: source,
    defaultValue: initialValue,
    rules: {
      validate: async (value) => {
        console.log("Validate do image uploader. Value: ", value);
        console.log("Validate do image uploader. State: ", state);
        switch (state) {
          case UploadState.UPLOADING:
            return "Aguarde o upload terminar";
          case UploadState.ERROR:
            return "Erro no upload";
          case UploadState.CHOSEN:
            return "Falta enviar a imagem";
          case UploadState.INITIAL:
            return record ? true : "Falta importar uma imagem";
          case UploadState.UPLOADED:
            return true;
          default:
            return "Estado de imagem inválido";
        }
      },
    },
  });
  const original_onchange = input_file.field.onChange;
  input_file.field.onChange = (event) => {
    const file = event.target.files[0];
    setFileId(file);
    setPreview(URL.createObjectURL(file));
    setState((old) => UploadState.import_image(old));
    original_onchange(event);
  };

  const [state, setState] = useState(UploadState.INITIAL);
  const [preview, setPreview] = useState(initialValue);
  const [fileId, setFileId] = useState("");
  const { progress, doUpload, status } = useUploadToS3();
  const [uploadForm, setUploadForm] = useState(null);

  const buttontext = fileId ? "ALTERAR" : "IMPORTAR";

  const imagePreview = preview ? (
    <img alt="" style={{ marginTop: "15px" }} src={preview} width="100%" />
  ) : (
    <></>
  );

  useEffect(() => {
    if (status === "done") {
      setState((old) => UploadState.uploaded(old));
    }
  }, [status]);

  useEffect(() => {
    if (uploadForm) {
      doUpload(uploadForm, fileId);
    }
  }, [uploadForm]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>

      {/*Input para obter a imagem do sistema de arquivos. Só serve pra isso.*/}
      <input
        ref={fileInputRef}
        type="file"
        accept={"image/*"}
        onChange={input_file.field.onChange}
        style={{
          position: "absolute",
          zIndex: -1,
          opacity: 0,
        }}
      />

      {/*Input que fará o papel de "field", que insere o valor no payload no final*/}
      <input {...input_file.field} type="hidden" value={fileId} />

      <Button
        variant="contained"
        color="primary"
        disabled={isLoading || state === UploadState.UPLOADING}
        onClick={() => fileInputRef.current.click()}
      >
        {buttontext}
      </Button>

      <ResetButton
        disabled={!UploadState.is_resetable(state)}
        reset_fn={() => {
          form.resetField(source);
          setPreview(record[source]);
          setFileId("");
          setState((old) => UploadState.reset(old));
        }}
      />

      <UploadButton
        state={state}
        upload_fn={() => {
          setState((old) => UploadState.upload(old));
          getSignedURL(isMatchBetStore).then((response) => {
            form.setValue(source, response.data.fields.key.replace("matchbet_store/", ""));
            setUploadForm(response.data);
          });
        }}
      />
      {state === UploadState.UPLOADING && (
        <LinearProgress variant="determinate" value={progress} />
      )}
      {imagePreview}
      {input_file.fieldState.error && (
        <div style={{ color: "red" }}>
          {input_file.fieldState.error.message}
        </div>
      )}
      {/*<Button onClick={() => {*/}
      {/*  console.log('#### ImageUploader #### input_file: ', input_file.field.value);*/}
      {/*}}>*/}
      {/*  Debug*/}
      {/*</Button>*/}
    </Box>
  );
};

export default ImageUploader;
