import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Amplify, Auth } from "aws-amplify";
import config from "./config/constants";

const COMMON = {
  region: config.COGNITO.REGION,
  custom_header: async () => {
    return {
      Authorization: (await Auth.currentSession()).idToken.jwtToken,
    };
  },
};

Amplify.configure({
  Auth: {
    region: config.COGNITO.REGION,
    userPoolId: config.COGNITO.USER_POOL_ID,
    userPoolWebClientId: config.COGNITO.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: config.APIS.MATCHBET_ADMIN,
        endpoint: config.APIURL,
        ...COMMON,
      },
      {
        name: config.APIS.MATCHBET_ADMIN_OPEN,
        endpoint: config.APIURL,
        region: config.COGNITO.REGION,
      },
    ],
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
