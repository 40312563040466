import {Edit} from 'react-admin';
import MatchBetStoreForm from "./matchbet_store_form";

const EditMatchbetStore = (props) => {
  return (
    <Edit {...props}>
      <MatchBetStoreForm/>
    </Edit>
  );
};

export default EditMatchbetStore;