import { Button } from "react-admin";
import React from "react";

export const BasicButton = props => {
  if (props.disabled) {
    return <></>;
  }
  const { label, ...rest } = props;
  return (
    <Button
      variant="contained"
      color="primary"
      {...rest}
    >
      {label}
    </Button>
  );
};