import './App.css';
import {Admin, Resource} from 'react-admin';

import dataProvider from "./services/data-provider";
import authProvider from "./services/auth-provider";

import ListTorneio from "./pages/torneio/list-torneio";
import CreateTorneio from "./pages/torneio/create-torneio";
import EditTorneio from "./pages/torneio/edit-torneio";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import ListGame from "./pages/games/list-game";
import CreateGame from "./pages/games/create-game";
import EditGame from "./pages/games/edit-game";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

import ListPlatform from "./pages/platforms/list-platform";
import CreatePlatform from "./pages/platforms/create-platform";
import EditPlatform from "./pages/platforms/edit-platform";
import MonitorIcon from '@mui/icons-material/Monitor';

import ListUsuario from './pages/usuario/list-usuario';
import CreateUsuario from './pages/platforms/create-platform';
import EditUsuario from './pages/usuario/edit-usuario';
import PersonIcon from '@mui/icons-material/Person';

import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import ShowUsuario from './pages/usuario/show-usuario';

import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import FinancialSection from './pages/transfer';

import DarkThemeLayout from './services/toggle-theme';
import ShowTorneio from './pages/torneio/show-tournament';
import ListAdminLogs from './pages/adminLogs/list-adminLogs';
import ShowAdminLog from './pages/adminLogs/show-adminLogs';

import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import {EditSystemParameters, ListSystemParameters} from './pages/system_parameters';

import StorefrontIcon from '@mui/icons-material/Storefront';
import ListNftStore from "./pages/nftStore/list-nftStore";

import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import ListNft from "./pages/nft/list-nft";
import EditNftStore from "./pages/nftStore/edit-nftStore";
import CreateNftStore from "./pages/nftStore/create-nftStore";
import EditNft from "./pages/nft/edit-nft";

import StoreIcon from '@mui/icons-material/Store';
import ListMatchBetStore from "./pages/matchbetStore/list-matchbetStore";
import EditMatchbetStore from "./pages/matchbetStore/edit-matchbetStore";
import CreateMatchbetStore from "./pages/matchbetStore/create-matchbetStore";

import LocalMallIcon from '@mui/icons-material/LocalMall';
import ListMatchbetProduct from "./pages/matchbetProduct/list-matchbetProduct";
import EditMatchbetProduct from "./pages/matchbetProduct/edit-matchbetProduct";
import CreateMatchbetProduct from "./pages/matchbetProduct/create-matchbetProduct";

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ListPurchaseOrder from "./pages/purchaseOrder/list-purchaseOrder";
import ShowCheckout from "./pages/purchaseOrder/show-checkout";


function App() {
  return (<Admin layout={DarkThemeLayout} dataProvider={dataProvider} authProvider={authProvider}>
      <Resource name={"tournaments"}
                list={ListTorneio}
                create={CreateTorneio}
                edit={EditTorneio}
                show={ShowTorneio}
                icon={EmojiEventsIcon}
                recordRepresentation={record => record.name}
      />
      <Resource name={"games"}
                list={ListGame}
                create={CreateGame}
                edit={EditGame}
                icon={SportsEsportsIcon}
                recordRepresentation={record => record.name}
      />
      <Resource name={"platforms"}
                list={ListPlatform}
                create={CreatePlatform}
                edit={EditPlatform}
                icon={MonitorIcon}
                recordRepresentation={record => record.name}
      />
      <Resource name={"usuarios"}
                list={ListUsuario}
                create={CreateUsuario}
                edit={EditUsuario}
                show={ShowUsuario}
                icon={PersonIcon}
                recordRepresentation={record => record.email}
      />
      <Resource name={"transfer"}
                list={FinancialSection}
                icon={LocalAtmIcon}
      />
      <Resource name={"admin_logs"}
                list={ListAdminLogs}
                show={ShowAdminLog}
                icon={ManageSearchIcon}
                recordRepresentation={record => record.id}
      />
      <Resource name={"system_parameterss"}
                list={ListSystemParameters}
                edit={EditSystemParameters}
                icon={SettingsSuggestIcon}
                recordRepresentation={record => record.id}
                options={{label: 'System Parameters'}}
      />
      <Resource name={"nfts"}
                list={ListNft}
                edit={EditNft}
                icon={CurrencyBitcoinIcon}
                recordRepresentation={record => record.id}
                options={{label: 'NFTs'}}
      />
      <Resource name={"nft_store_items"}
                list={ListNftStore}
                edit={EditNftStore}
                create={CreateNftStore}
                icon={StorefrontIcon}
                recordRepresentation={record => record.id}
                options={{label: 'NFT Store'}}
      />
      <Resource name={"products"}
                list={ListMatchbetProduct}
                edit={EditMatchbetProduct}
                create={CreateMatchbetProduct}
                icon={LocalMallIcon}
                recordRepresentation={record => record.id}
                options={{label: 'Products'}}
      />
      <Resource name={"match_bet_store_items"}
                list={ListMatchBetStore}
                edit={EditMatchbetStore}
                create={CreateMatchbetStore}
                icon={StoreIcon}
                recordRepresentation={record => record.id}
                options={{label: 'MatchBet Store'}}
      />
      <Resource name={"purchase_orders"}
                list={ListPurchaseOrder}
                show={ShowCheckout}
                icon={AccountBalanceWalletIcon}
                recordRepresentation={record => record.id}
                options={{label: 'Purchase Orders'}}
      />
      <Resource name={"usertransactions"}/>
      <Resource name={"userbattles"}/>
      <Resource name={"userindications"}/>
      <Resource name={"tournamentsubscribers"}/>
      <Resource name={"financial/currencies"}
                recordRepresentation={record => record.currency}
      />
      <Resource name={"purchaseorderscheckout"}/>
      <Resource name={"changecheckoutstatus"}/>
    </Admin>);
}

export default App;
