import {Edit} from 'react-admin';
import NftStoreForm from "./nft_store_form";

const EditNftStore = (props) => {
  return (
    <Edit {...props}>
      <NftStoreForm/>
    </Edit>
  );
};

export default EditNftStore;