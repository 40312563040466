import {Edit} from 'react-admin';
import NftForm from "./nft_form";

const EditNft = (props) => {
  return (
    <Edit {...props}>
      <NftForm/>
    </Edit>
  );
};

export default EditNft;