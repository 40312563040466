/* eslint-disable import/no-anonymous-default-export */
import { Auth } from "aws-amplify";

const login = async (username, password) => {
  return Auth.signIn(username, password)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response;
    })
    .then(async () => {
      const token = (await Auth.currentSession()).idToken.jwtToken;
      localStorage.setItem("token", token);
    })
    .catch((error) => {
      console.log("Erro fazendo login: ", error);
      throw new Error(error.message);
    });
};

const logout = () => {
  Auth.signOut();
  localStorage.removeItem("token");
  return Promise.resolve();
};

const authError = (status) => {
  if (status === 401 || status === 403) {
    Auth.signOut();
    localStorage.removeItem("token");
    return Promise.reject();
  }
  return Promise.resolve();
};

const authCheck = async () => {
  const currentSession = await Auth.currentSession();
  if (currentSession.idToken.jwtToken) {
    return Promise.resolve(localStorage.getItem("token"));
  }

  Auth.signOut();
  localStorage.removeItem("token");
  return Promise.reject();
};

const authProvider = {
  login: ({ username, password }) => {
    return login(username, password);
  },
  logout: () => logout(),
  checkAuth: () => authCheck(),
  checkError: (error) => authError(error.status),
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
