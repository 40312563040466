export class UploadState {
  static INITIAL = "INITIAL";
  static CHOSEN = "CHOSEN";
  static UPLOADING = 'UPLOADING';
  static UPLOADED = 'UPLOADED';
  static ERROR = 'ERROR';

  static log_state_change(old_state, new_state) {
    console.log(`State changed from ${old_state} to ${new_state}`);
  }

  static change_state(old_state, new_state) {
    UploadState.log_state_change(old_state, new_state);
    return new_state;
  }

  static reset(old_state) {
    if (old_state !== UploadState.CHOSEN) {
      throw new Error(`Invalid state: reset from ${old_state}`);
    }
    return UploadState.change_state(old_state, UploadState.INITIAL);
  }

  static import_image(old_state) {
    if (old_state !== UploadState.INITIAL && old_state !== UploadState.CHOSEN) {
      throw new Error(`Invalid state: import image from ${old_state}`);
    }
    return UploadState.change_state(old_state, UploadState.CHOSEN);
  }

  static upload(old_state) {
    if (old_state !== UploadState.CHOSEN) {
      throw new Error(`Invalid state: upload from ${old_state}`);
    }
    return UploadState.change_state(old_state, UploadState.UPLOADING);
  }

  static uploaded(old_state) {
    if (old_state !== UploadState.UPLOADING) {
      throw new Error(`Invalid state: uploaded from ${old_state}`);
    }
    return UploadState.change_state(old_state, UploadState.UPLOADED);
  }

  static is_resetable(state) {
    return state === UploadState.CHOSEN;
  }

  static is_importable(state) {
    return state === UploadState.INITIAL;
  }

  static is_uploadable(state) {
    return state === UploadState.CHOSEN;
  }

  static is_modifiable(state) {
    return state === UploadState.UPLOADED || state === UploadState.CHOSEN;
  }
}