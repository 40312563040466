import {BooleanField, ChipField, Datagrid, DateField, List, ShowButton, TextField} from 'react-admin';
import {SearchByStatus, SearchByUser} from "./filters/search_filter";
import {IsDelivered} from "./filters/is_delivered";


const ListPurchaseOrder = props => {
  return (
    <List {...props} filters={[SearchByStatus, SearchByUser, IsDelivered]}>
      <Datagrid>
        <ChipField source="id" />
        <TextField source="user_id" />
        <TextField source="product_store_item_id" />
        <ChipField source="status" />
        <TextField source="item_count" />
        <BooleanField source="delivered" />
        <DateField source="delivered_at" />
        <DateField source="updated_at" />
        <DateField source="created_at" />
        <ShowButton label="Checkout"/>
      </Datagrid>
    </List>
  );
}
export default ListPurchaseOrder;