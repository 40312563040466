import {ChipField, Datagrid, EditButton, List, ReferenceField, TextField} from 'react-admin';


const ListNftStore = props => {
  return (
    <List {...props}>
      <Datagrid>
        <ChipField source="id" />
        <TextField source="title" />
        <TextField source="subtitle" />
        <ReferenceField reference="nfts" source="nft_id" label="NFT">
          <ChipField source="id" />
        </ReferenceField>
        <TextField source="price" />
        <TextField source="validity" label="Validity (days)"/>
        <EditButton />
      </Datagrid>
    </List>
  );
}
export default ListNftStore;