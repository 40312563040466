import { BasicButton } from "./BasicButton";
import { UploadState } from "./UploadState";
import React from "react";
import PropTypes from "prop-types";

export const UploadButton = (props) => {
  console.log("UploadButton props: ", props);

  return (
    <BasicButton
      sx={{ marginLeft: "15px" }}
      disabled={!UploadState.is_uploadable(props.state)}
      label="ENVIAR"
      onClick={props.upload_fn}
    />
  );
};
UploadButton.proptype = {
  state: PropTypes.string.isRequired,
  upload_fn: PropTypes.func.isRequired,
};
