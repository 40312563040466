import {SimpleForm, TextInput} from "react-admin";
import React from "react";

const NftForm = () => {
  return (
    <SimpleForm>
      <TextInput source="id" label={"Id"} fullWidth disabled/>
      <TextInput source="description" label={'Description'}/>
      <TextInput source="name" />
      <TextInput source="image_url" />
      <TextInput source="video_url" />
    </SimpleForm>
  );
};
export default NftForm;
