import { Show } from 'react-admin';
import UsuarioShowForm from './usuario-show-form'

const ShowUsuario = (props) => {
  return (
    <Show {...props}>
      <UsuarioShowForm/>
    </Show>
  );
};

export default ShowUsuario;