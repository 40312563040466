import {List, Datagrid, TextField, EditButton, ChipField} from 'react-admin';

const ListGame = props => {
  return (
    <List {...props}>
      <Datagrid>
        <ChipField source="id" />
        <TextField source="name" />
        <TextField source="validator_name" />
        <ChipField source="status"/>
        <EditButton />
      </Datagrid>
    </List>
  );
}
export default ListGame;