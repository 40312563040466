// noinspection NpmUsedModulesInstalled

import React from 'react';
import {
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
} from 'react-admin';
import { Paper, Typography } from '@mui/material';
import { useFormContext } from "react-hook-form";

const TransferSaveButton = props => {
  const notify = useNotify();
  const { reset } = useFormContext();

  const onSuccess = _ => {
    notify('Transferência feita com sucesso!', {type: "success"});
    reset();
  };

  return <SaveButton {...props}
                     label={'Transferir'}
                     mutationOptions={{ onSuccess }}
                     type="button"
                     onClick={()=> {alert("Ao clicar em OK, você confirma a sua transferência. Caso contrário, recarregue a página.")}}
  />;
};

const TransferToolbar = props => (
  <Toolbar {...props}>
    <TransferSaveButton/>
  </Toolbar>
);

const FinancialSection = () => {
  const AutoCompleteInputEmail = props => (
    <AutocompleteInput
      {...props}
      filterToQuery={searchText => ({ email: searchText })}
    />
  );

  return (
    <Create redirect="list">
      <SimpleForm toolbar={<TransferToolbar/>}>
        <Paper sx={{ width: "97%", padding: 2, margin: 2 }}>
          <Typography align="center" variant="h5" gutterBottom>Transferir saldo entre
            usuários</Typography>
          <NumberInput source="value" label="Valor" min={0} fullWidth/>
          <ReferenceInput source="user_debit"
                          label="ID do usuário a debitar"
                          reference="usuarios"
                          fullWidth
                          queryOptions={{ meta: { includeMatchBet: true } }}>
            <AutoCompleteInputEmail/>
          </ReferenceInput>
          <ReferenceInput source="user_credit"
                          label="ID do usuário a creditar"
                          reference="usuarios"
                          fullWidth>
            <AutoCompleteInputEmail/>
          </ReferenceInput>
        </Paper>
      </SimpleForm>
    </Create>
  );
};

export default FinancialSection;