import { List, Datagrid, TextField, EditButton, ImageField, ChipField } from 'react-admin';

const ListPlatform = props => {
  return (
    <List {...props}>
      <Datagrid sx={{
                background: "#0000000d"
            }}>
        <ChipField source="id" />
        <TextField source="name" />
        <ImageField source="image_url" />
        
        
        <EditButton />
      </Datagrid>
    </List>
  );
}
export default ListPlatform;