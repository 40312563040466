import {List, Datagrid, TextField, ShowButton, EditButton, ChipField } from 'react-admin';
import { IsInvitedFilter } from './filters/inveted_by_filter';
import { SearchByUsername, SearchByEmail, SearchByEntityId } from './filters/search_filter';


const ListUsuario = props => {
  return (
    <List {...props} filters={[IsInvitedFilter, SearchByUsername, SearchByEmail, SearchByEntityId]}>
      <Datagrid>
        <ChipField source="id" />
        <TextField source="apelido" />
        <TextField source="email" />
        <TextField source="telefone" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
}
export default ListUsuario;