import { DateTimeInput, NumberInput, ReferenceInput, SimpleForm, TextInput } from "react-admin";
import React from "react";
import { RichTextInput } from "ra-input-rich-text";
import ImageUploader from "../../Components/S3ImageUploader/S3ImageUploader";

const TorneioForm = () => {
  return (
    <SimpleForm>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        marginLeft: '1rem',
        marginRight: '1rem',
      }}>
        <TextInput source="name" label={'Nome'} fullWidth/>
        <TextInput source="description" label={'Descrição'} fullWidth/>
        <TextInput source="tournament_type" disabled label={'Tipo do Torneio'}
                   defaultValue="KNOCKOUT" fullWidth/>
        <RichTextInput source="rules" label={'Regras'} fullWidth/>
        <TextInput source="image_url" label={"Imagem"} fullWidth/>
        <ImageUploader source="image_url" label="Imagem"/>

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'stretch',
          width: '100%'
        }}>
          <DateTimeInput source="start_date" label={"Data início"}/>
          <div style={{ flexGrow: 5 }}>
            <ReferenceInput source={"game_id"} reference={"games"} label={"Jogo"}/>
          </div>
          <div>
            <ReferenceInput source="platform_id" label={"Plataforma"} reference="platforms"/>
          </div>
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%'
        }}>
        <NumberInput source="min_players" max={1024} min={2} label={"Mínimo de jogadores"}
                     fullWidth/>
        <NumberInput source="max_players" max={1024} min={2} label={"Máximo de jogadores"}
                    fullWidth/>
          <NumberInput source="stage_infos.stage_time" max={23} min={1}
                       label={"Duração da fase (horas)"} fullWidth/>
          <NumberInput source="stage_infos.stages_per_day" min={1} max={23} label={"Fases por dia"}
                       fullWidth/>
          <NumberInput source="stage_infos.time_between_stages" min={5} max={59}
                       label={"Tempo entre fases (minutos)"} fullWidth/>
        </div>
        <NumberInput source="stage_infos.ready_timeout" min={1} max={59}
                     label={"Tempo limite para ready (minutos)"} fullWidth/>
        <TextInput source="entry_fee" label={"Preço da entrada (MATCHCOINS)"} fullWidth/>
        <TextInput source="first_prize" label={"Prêmio do primeiro colocado"} fullWidth/>
        <TextInput source="second_prize" label={"Prêmio do segundo colocado"} fullWidth/>
        <TextInput source="third_prize" label={"Prémio do terceiro colocado"} fullWidth/>
      </div>
    </SimpleForm>
  );
};
export default TorneioForm;
