import { ReferenceArrayInput, SelectInput, SimpleForm, TextInput } from "react-admin";

const GameForm = () => {
  return (
    <SimpleForm>
      <TextInput source="name" label={'Nome'}/>
      <TextInput source="validator_name" label={'Validador'}/>
      <TextInput source="image_url" label={'Imagem'}/>
      <ReferenceArrayInput source="platforms"
                           label="Plataforma"
                           reference="platforms"
                           fullWidth>
      </ReferenceArrayInput>
      <SelectInput source="status" label={"Status"} choices={[
        { id: 'active', name: 'Ativo' },
        { id: 'inactive', name: 'Inativo' },
      ]}/>
    </SimpleForm>
  );
};
export default GameForm;
