import React from "react";
import {
  TextField,
  ImageField,
  SimpleShowLayout,
  DateField,
  ArrayField,
  Datagrid,
} from 'react-admin';

const TorneioShowForm = () => {
  return (
    <SimpleShowLayout>
      <TextField source="id" label="id"/>
      <TextField source="status" label="Status"/>
      <TextField source="tournament_type" label="Tournament Type"/>
      <TextField source="name" label="Name"/>
      <DateField source="start_date" label="Start Date"/>
      <ImageField source="image_url" label="Image"/>
      <DateField showTime source="created_at" label="Created At"/>
      <TextField source="description" label="Description"/>
      <TextField source="entry_fee" label="Entry Fee"/>
      <TextField source="first_prize" label="First Prize"/>
      <TextField source="second_prize" label="Second Prize"/>
      <TextField source="third_prize" label="Third Prize"/>
      <TextField source="game_id" label="Game ID"/>
      <TextField source="max_players" label="Max Players"/>
      <TextField source="min_players" label="Min Players"/>
      <TextField source="platform_id" label="Platform ID"/>
      <TextField source="registered_players" label="Registered Players"/>
      <TextField source="rules" label="Rules"/>
      <ArrayField source="stages">
          <Datagrid bulkActionButtons={false}>
              <TextField source="order"/>
              <ArrayField source="tournament_battles">
                <Datagrid bulkActionButtons={false}>
                  <TextField source="order" label="Order"/>
                  <DateField showTime source="date" label="Date" />
                  <TextField source="battle_id" label="Battle ID"/>
                </Datagrid>
              </ArrayField>
          </Datagrid>
      </ArrayField>
      <ArrayField source="tournament_players">
          <Datagrid bulkActionButtons={false}>
              <TextField source="nickname" label="Nickname"/>
              <TextField source="user_id" label="User ID"/>
          </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};
export default TorneioShowForm;
