import {Create} from 'react-admin';
import NftStoreForm from "./nft_store_form";

const CreateNftStore = (props) => {
  return (
    <Create {...props} redirect="list">
      <NftStoreForm/>
    </Create>
  );
};

export default CreateNftStore;