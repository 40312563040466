import { TextInput } from 'react-admin';

export const SearchByUsername = (
    <TextInput source="apelido" label="Buscar por apelido" alwaysOn />
)

export const SearchByEmail = (
    <TextInput source="email" label="Buscar por email" alwaysOn />
)

export const SearchByEntityId = (
    <TextInput source="entity_id" label="Buscar por ID" alwaysOn />
)