import { BasicButton } from "./BasicButton";
import React from "react";
import PropTypes from "prop-types";

export const ResetButton = (props) => {
  return (
    <BasicButton
      sx={{ marginLeft: "15px" }}
      disabled={props.disabled}
      label="RESET"
      onClick={props.reset_fn}
    />
  );
};
ResetButton.proptype = {
  disabled: PropTypes.bool,
  reset_fn: PropTypes.func.isRequired,
};
