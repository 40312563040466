import {SimpleForm, TextInput} from "react-admin";
import React from "react";
import ImageUploader from "../../Components/S3ImageUploader/S3ImageUploader";

const MatchBetProductForm = () => {
  return (
    <SimpleForm>
      <TextInput source="id" label={"Id"} fullWidth disabled/>
      <TextInput source="name" label={"Name"} fullWidth/>
      <TextInput source="description" label={"Description"} fullWidth/>
      <TextInput source="image_url" label={"Image Key"} fullWidth parse={(value) => value.replace("matchbet_store/", "")}/>
      <ImageUploader source="image_url" label="Image" isMatchBetStore/>
    </SimpleForm>
  );
};
export default MatchBetProductForm;
