import { List, Datagrid, TextField, ShowButton, EditButton, DateField, ChipField } from 'react-admin';

const ListTorneio = props => {
  return (
    <List {...props}>
      <Datagrid>
        <ChipField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <DateField source="start_date" showTime locales={'pt-BR'} />
        <TextField source="first_prize" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
}
export default ListTorneio;