import { Create } from 'react-admin';
import TorneioForm from "./torneio_form";

const CreateTorneio = (props) => {
  return (
    <Create {...props} redirect="list">
      <TorneioForm/>
    </Create>
  );
};

export default CreateTorneio;