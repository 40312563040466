/* eslint-disable import/no-anonymous-default-export */
// noinspection NpmUsedModulesInstalled
import config from "../config/constants";
import { API } from "aws-amplify";

const CREATE = "CREATE";
const GET_LIST = "GET_LIST";
const GET_ONE = "GET_ONE";
const GET_MANY = "GET_MANY";
const UPDATE = "UPDATE";
const DELETE = "DELETE";

export default {
  getList: (resource, params) => oldOne()(GET_LIST, resource, params),
  getOne: (resource, params) => oldOne()(GET_ONE, resource, params),
  getMany: (resource, params) => oldOne()(GET_MANY, resource, params),
  getManyReference: (resource, params) => oldOne()(GET_MANY, resource, params),
  create: (resource, params) => oldOne()(CREATE, resource, params),
  update: (resource, params) => oldOne()(UPDATE, resource, params),
  // updateMany: (resource, params) =>
  delete: (resource, params) => oldOne()(DELETE, resource, params),
  // deleteMany: (resource, params) =>
};


const oldOne = () => {
  const convertDataRequestToHTTP = (type, resource, params) => {
    let options = { response: true, queryStringParameters: {} };
    let method;

    switch (type) {
      case GET_LIST: {
        const queryStringParameters = {
          pagination_page: params.pagination.page,
          pagination_perPage: params.pagination.perPage,
          sort_field: params.sort.field,
          sort_order: params.sort.order,
          filter_field: Object.keys(params.filter)[0],
          filter_value: Object.values(params.filter)[0],
        };

        Object.keys(queryStringParameters).forEach((key) => {
          if (queryStringParameters[key] && queryStringParameters[key] !== "id")
            options.queryStringParameters[key] = queryStringParameters[key];
        });

        method = "GET";
        break;
      }
      case GET_ONE: {
        method = "GET";
        break;
      }
      case GET_MANY: {
        method = "GET";
        break;
      }

      case UPDATE: {
        const { id, ...data } = params.data;
        options.body = { ...data, entity_id: id };
        method = "PUT";
        break;
      }
      case CREATE: {
        options.body = params.data;
        method = "POST";
        break;
      }
      case DELETE: {
        options.body = params.data;
        method = "DELETE";
        break;
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { method, options };
  };

  const convertHTTPResponse = (response, type, resource, params) => {
    const data = response.data.data || response.data;
    // console.log("DATA PROVIDER :: ", data, type);

    switch (type) {
      case GET_LIST:
      case GET_MANY:
        if (!response.headers.hasOwnProperty("content-range")) {
          throw new Error(
            "The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?"
          );
        }
        const total_str = String(response.headers["content-range"].split("/").pop());
        const total = parseInt(total_str, 10);
        let data_with_id = {
          data: data.map((value) => ({
            id: value.entity_id || value.value,
            ...value,
          })),
          total
        };

        if (params.meta && resource === "usuarios" && params.meta.includeMatchBet) {
          data_with_id.data = [{email: "<<< MATCHBET >>>", id: "MATCHBET"}, ...data_with_id.data]
        }
        
        return data_with_id
      case GET_ONE:
        return {
          data: { id: data.entity_id, ...data },
        };
      case UPDATE:
        return { data: { ...params.data, id: data.entity_id } };
      case CREATE:
        return { data: { ...params.data, id: data.entity_id } };
      case DELETE:
        return { data: { previousData: params.data, id: data.entity_id } };
      default:
        return { data: data.data };
    }
  };

  return (type, resource, params) => {
    const { method, options } = convertDataRequestToHTTP(
      type,
      resource,
      params
    );
    const id = params.id ? "/" + params.id : "";
    const api_name = config.APIS.MATCHBET_ADMIN;
    options.response = true;
    // options.queryStringParameters = {}

    switch (method) {
      case "GET":
        return API.get(api_name, resource + id, options).then((response) =>
          convertHTTPResponse(response, type, resource, params)
        );
      case "PUT":
        const _id = "/" + options.body.entity_id;
        return API.put(api_name, resource + _id, options)
          .then((response) =>
            convertHTTPResponse(response, type, resource, params)
          )
          .catch((error) => {
            console.log("ERROR PUT :: ", error);
            throw error;
          });
      case "POST":
        return API.post(api_name, resource, options).then((response) =>
          convertHTTPResponse(response, type, resource, params)
        );
      case "DELETE":
        return API.del(api_name, resource + id, options).then((response) =>
          convertHTTPResponse(response, type, resource, params)
        );
      default:
        throw new Error("Método não encontrado");
    }
  };
};
