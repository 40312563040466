import { Edit } from 'react-admin';
import PlatformForm from "./platform_form";

const EditPlatform = (props) => {
  return (
    <Edit {...props}>
      <PlatformForm/>
    </Edit>
  );
};

export default EditPlatform;