import { Edit } from 'react-admin';
import UsuarioForm from "./usuario_form";

const EditUsuario = (props) => {
  return (
    <Edit {...props}>
      <UsuarioForm/>
    </Edit>
  );
};

export default EditUsuario;