import {ChipField, Datagrid, EditButton, List, TextField, BooleanField, ReferenceField} from 'react-admin';

const ListMatchBetStore = props => {
  return (
    <List {...props}>
      <Datagrid>
        <ChipField source="id" />
        <ReferenceField reference="products" source="product_id" label="Product">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="price" />
        <TextField source="units_pack" />
        <BooleanField source="active" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
export default ListMatchBetStore;