import {NumberInput, ReferenceInput, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar} from "react-admin";
import React from "react";

const SystemParametersEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

const SystemParametersForm = () => {
  
  return (
    <SimpleForm toolbar={<SystemParametersEditToolbar />}>
      <TextInput source="id" label="id" disabled name="id"/>
      <NumberInput source="battle_duration_minutes" label="Battle Duration Minutes" min={1} name="battle_duration_minutes"/>
      <ReferenceInput source="currency"
                      name="currency"
                      label="Currency"
                      reference="financial/currencies"
                      fullWidth>
        <SelectInput />
      </ReferenceInput>
    </SimpleForm>
  );
};

export default SystemParametersForm;
