import * as React from 'react';
import { defaultTheme, Layout, AppBar, ToggleThemeButton } from 'react-admin';
import { createTheme, Box, Typography } from '@mui/material';

const darkTheme = createTheme({
    palette: { mode: 'dark' },
});

const DarkThemeAppBar = props => (
    <AppBar style={{background: "#2b005e"}} {...props}>
        <Box flex="1" >
            <Typography  variant="h6" id="react-admin-title" style={{position: 'relative'}}>
                <img 
                    style={{
                        height: 30, 
                        display: "block", 
                        marginInline: "auto", 
                        position: 'absolute', 
                        right: "45%"
                    }} 
                    src="https://sales.matchbet.io/wp-content/uploads/2022/12/matchbet-line.png" 
                    alt="matchbet"
                />
            </Typography>
        </Box>
        <ToggleThemeButton
            lightTheme={defaultTheme}
            darkTheme={darkTheme}
        />
    </AppBar>
);

const DarkThemeLayout = props => <Layout {...props} appBar={DarkThemeAppBar} />;

export default DarkThemeLayout