import {Create} from 'react-admin';
import MatchbetStoreForm from "./matchbet_product_form";

const CreateMatchbetProduct = (props) => {
  return (
    <Create {...props} redirect="list">
      <MatchbetStoreForm/>
    </Create>
  );
};

export default CreateMatchbetProduct;