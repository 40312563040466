import {ChipField, Datagrid, EditButton, List, TextField} from 'react-admin';


const ListMatchbetProduct = props => {
  return (
    <List {...props}>
      <Datagrid>
        <ChipField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
export default ListMatchbetProduct;