import { Edit } from 'react-admin';
import GameForm from "./game_form";

const EditGame = (props) => {
  return (
    <Edit {...props}>
      <GameForm/>
    </Edit>
  );
};

export default EditGame;