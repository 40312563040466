import React from "react";
import {
  Datagrid,
  TextField,
  DateField,
  Tab,
  TabbedShowLayout,
  ReferenceManyField
} from 'react-admin';

const UsuarioShowForm = () => {
  return (
    <TabbedShowLayout>
      <Tab label="Profile">
        <TextField source="id" label="id"/>
        <TextField source="apelido" label='Nome'/>
        <TextField source="email" label='Email'/>
        <DateField source="nascimento" label='Data de nascimento'/>
        <TextField source="telefone" label='Telefone'/>
        <TextField source="invitation_code" label='Código de convite'/>
        <TextField source="image_url" label="Image Url"/>
        <TextField source="invited_by" label="Quem convidou"/>
      </Tab>
      <Tab label="Financial">
        <ReferenceManyField label="" target="user_id" reference="usertransactions">
          <Datagrid bulkActionButtons={false}>
            <DateField showTime source="date" label="Date"/>
            <TextField source="status" label="Status"/>
            <TextField source="type" label="Type"/>
            <TextField source="value" label="Value"/>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Battles">
        <ReferenceManyField label="" target="user_id" reference="userbattles">
          <Datagrid bulkActionButtons={false}>
            <TextField source="entity_id" label="ID"/>
            <DateField showTime source="created_at" label="Created At"/>
            <DateField showTime source="expiration" label="Expiration"/>
            <TextField source="status" label="Status"/>
            <TextField source="battle_type" label="Type"/>
            <TextField source="amount" label="Amount"/>
            <TextField source="created_by" label="Created By"/>
            <TextField source="player1" label="Player 1"/>
            <TextField source="player2" label="Player 2"/>
            <TextField source="selected_platform" label="Selected Platform"/>
            <TextField source="selected_game" label="Selected Game"/>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Indications">
        <ReferenceManyField label="" target="user_id" reference="userindications">
          <Datagrid bulkActionButtons={false}>
            <TextField source="entity_id" label="ID"/>
            <TextField source="apelido" label='Nome'/>
            <TextField source="email" label='Email'/>
            <DateField source="nascimento" label='Data de nascimento'/>
            <TextField source="telefone" label='Telefone'/>
            <TextField source="invitation_code" label='Código de convite'/>
            <TextField source="image_url" label="Image Url"/>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Purchase">

      </Tab>
    </TabbedShowLayout>
  );
};
export default UsuarioShowForm;
