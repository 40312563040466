const COGNITO_DEV = {
  REGION: "us-east-1",
  USER_POOL_ID: "us-east-1_SAFyEXyYV",
  APP_CLIENT_ID: "6e1np1nb72skrg64f76e3mam1k",
};

const COGNITO_STG = {
  REGION: "us-east-1",
  USER_POOL_ID: "us-east-1_4zZh1BeY3",
  APP_CLIENT_ID: "4cll8qgcca18jsjbhsjo7rrc2s",
};

const COGNITO_PRD = {
  REGION: "us-east-1",
  USER_POOL_ID: "us-east-1_cjrNGurhV",
  APP_CLIENT_ID: "1sd037qgodb8j812aset2l26go",
};

const APIS = {
  MATCHBET_ADMIN: "matchbet_admin",
  MATCHBET_ADMIN_OPEN: "matchbet_admin_open",
};

const STAGES = {
  dev: {
    COGNITO: COGNITO_DEV,
    APIS,
    APIURL: "https://lsax5kj0t8.execute-api.us-east-1.amazonaws.com/dev/",
  },
  stg: {
    COGNITO: COGNITO_STG,
    APIS,
    APIURL: "https://oq0t2cive6.execute-api.us-east-1.amazonaws.com/stg/",
  },
  prd: {
    COGNITO: COGNITO_PRD,
    APIS,
    APIURL: "https://lf9opg6p1h.execute-api.us-east-1.amazonaws.com/prd/",
  },
};

const env = process.env.REACT_APP_STAGE || "dev";
const config = STAGES[env];

export default config;
