import {Edit} from 'react-admin';
import MatchBetStoreForm from "./matchbet_product_form";

const EditMatchbetProduct = (props) => {
  return (
    <Edit {...props}>
      <MatchBetStoreForm/>
    </Edit>
  );
};

export default EditMatchbetProduct;