import {
  ArrayField,
  Button,
  ChipField,
  Datagrid,
  Loading,
  Show,
  SimpleShowLayout,
  TextField,
  useDataProvider
} from 'react-admin';
import React from "react";

const ShowCheckout = (props) => {
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const dataProvider = useDataProvider();
  
  React.useEffect(() => {
    const url = window.location.href // https://.../#/purchase_orders/ebaae2f5-2656-4df0-857a-2f2f43820686/show
    const id = url.split("/")[url.split("/").length - 2]
    dataProvider.getOne('purchaseorderscheckout', {id: id})
      .then(({data}) => {
        setData(data);
      });
  }, [dataProvider, props]);
  
  if (!data) return <Loading/>;
  
  const buttonAction = isLoading ? "Loading..." : (data.status === "INACTIVE" ? "activate" : "inactivate");
  
  return (
    <>
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="Id" record={data}/>
          <ChipField source="status" label="Status" record={data}/>
          <TextField source="soft_descriptor" record={data}/>
          <ArrayField source="payment_methods" record={data}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="type"/>
            </Datagrid>
          </ArrayField>
          <ArrayField source="items" record={data}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="reference_id"/>
              <TextField source="name"/>
              <TextField source="quantity"/>
              <TextField source="unit_amount"/>
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </Show>
      <Button label={buttonAction} disabled={isLoading} style={{alignSelf: "flex-start", marginTop: 10}} variant="outlined" color="secondary"
              onClick={() => {
                setIsLoading(true);
                dataProvider.create(`changecheckoutstatus/${data.id}`, {data: {action: buttonAction}}).then(() => {
                  if (buttonAction === "activate") {
                    setData({...data, status: "ACTIVE"})
                  } else {
                    setData({...data, status: "INACTIVE"})
                  }
                  setIsLoading(false);
                })
              }}/>
    </>
  );
};

export default ShowCheckout;